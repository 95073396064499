var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VContent',[_c('template',{slot:"right"},[_c('span',[_vm._v("طلبات أنشاء الحساب")])]),_c('template',{slot:"content"},[_c('b-table',{attrs:{"items":_vm.items,"fields":_vm.fields,"tbody-transition-props":_vm.transProps,"tbody-tr-class":"text-muted","responsive":"","table-class":"table-font h-100"},scopedSlots:_vm._u([{key:"cell(id)",fn:function(data){return [_vm._v(" "+_vm._s((_vm.page - 1) * 10 + data.index + 1)+" ")]}},{key:"cell(name)",fn:function(ref){
var item = ref.item;
return [_c('img',{staticClass:"bg-info rounded-circle mx-3",attrs:{"src":_vm.ImageURL(item),"width":"35","height":"35"}}),_vm._v(" "+_vm._s(item.fullname)+" ")]}},{key:"cell(account_type)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"p-2 rounded-pill green"},[_vm._v(" "+_vm._s(_vm.accountType(item.account_type))+" ")])]}},{key:"cell(governate)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"p-2 rounded-pill orange"},[_vm._v(" "+_vm._s(_vm.governanteName(item.governate))+" ")])]}},{key:"cell(created_at)",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(new Date(item.created_at).toISOString().slice(0, 10)))]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
return [_c('b-dropdown',{attrs:{"size":"lg","variant":"link","no-caret":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('b-icon',{attrs:{"icon":"three-dots","animation":"cylon"}})]},proxy:true}],null,true)},[_c('b-dropdown-item',{on:{"click":function($event){return _vm.remove(item.id)}}},[_c('b-icon',{staticClass:"ml-2",attrs:{"icon":"archive","scale":"0.5"}}),_vm._v(" حذف")],1)],1)]}}])})],1),_c('template',{slot:"bottom"},[_c('b-pagination',{attrs:{"current-page":_vm.meta.current_page,"per-page":_vm.meta.per_page,"total-rows":_vm.meta.last_page * _vm.meta.per_page},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }