<template>
  <VContent>
    <template slot="right">
      <span>طلبات أنشاء الحساب</span>
    </template>
    <template slot="content">
      <b-table
        :items="items"
        :fields="fields"
        :tbody-transition-props="transProps"
        tbody-tr-class="text-muted"
        responsive
        table-class="table-font h-100"
      >
        <template #cell(id)="data">
          {{ (page - 1) * 10 + data.index + 1 }}
        </template>
        <template v-slot:cell(name)="{ item }">
          <img
            :src="ImageURL(item)"
            width="35"
            height="35"
            class="bg-info rounded-circle mx-3"
          />
          {{ item.fullname }}
        </template>
        <template v-slot:cell(account_type)="{ item }">
          <div class="p-2 rounded-pill green">
            {{ accountType(item.account_type) }}
          </div>
        </template>
        <template v-slot:cell(governate)="{ item }">
          <div class="p-2 rounded-pill orange">
            {{ governanteName(item.governate) }}
          </div>
        </template>
        <template v-slot:cell(created_at)="{ item }">{{
          new Date(item.created_at).toISOString().slice(0, 10)
        }}</template>
        <template v-slot:cell(actions)="{ item }">
          <b-dropdown size="lg" variant="link" no-caret>
            <template #button-content>
              <b-icon icon="three-dots" animation="cylon"></b-icon>
            </template>
            <b-dropdown-item @click="remove(item.id)">
              <b-icon icon="archive" scale="0.5" class="ml-2"></b-icon>
              حذف</b-dropdown-item
            >
          </b-dropdown>
        </template>
      </b-table>
    </template>
    <template slot="bottom">
      <b-pagination
        v-model="page"
        :current-page="meta.current_page"
        :per-page="meta.per_page"
        :total-rows="meta.last_page * meta.per_page"
      ></b-pagination>
    </template>
  </VContent>
</template>

<script>
import {
  successAlert,
  errorAlert,
  showAlertWithConfirm,
  ImageURL,
} from "@/utils/helper";

export default {
  data() {
    return {
      page: 1,
      readonly: false,
      transProps: {
        name: "flip-list",
      },
      fields: [
        {
          key: "id",
          label: "أ",
          sortable: true,
        },
        {
          key: "name",
          label: "الأسم",
          sortable: true,
        },
        {
          key: "phone",
          label: "رقم الهاتف",
          sortable: true,
        },
        {
          key: "account_type",
          label: "نوع الحساب",
          sortable: true,
        },
        {
          key: "governate",
          label: "المحافظة",
          sortable: true,
        },
        {
          key: "birth",
          label: "تاريخ  الميلاد",
          sortable: true,
        },
        {
          key: "job",
          label: "الوظيفة",
          sortable: true,
        },
        {
          key: "created_at",
          label: "تاريخ  الطلب",
          sortable: true,
        },
        {
          key: "actions",
          label: "العمليات",
          sortable: true,
        },
      ],
      types: [
        { name: "حساب التوفير والادخار", id: 0 },
        { name: "حسابات الودائع الثابتة", id: 1 },
        { name: "حسابات الودائع الوقتية", id: 2 },
        { name: "حسابات جارية", id: 3 },
        { name: "شهادة الايداع", id: 4 },
      ],
    };
  },
  computed: {
    items() {
      return this.$store.state.reviewer.requests.accounts.list;
    },
    meta() {
      return this.$store.state.reviewer.requests.accounts.meta;
    },
    governatesList() {
      return this.$store.state.banks.branches.governates;
    },
  },
  watch: {
    page() {
      this.getAll();
    },
  },
  mounted() {
    this.getAll();
  },
  methods: {
    getAll() {
      this.$store.dispatch("reviewer/requests/accounts/get", this.page);
    },
    governanteName(id) {
      return this.governatesList.find(
        (element) => element.value == id.toString()
      ).text;
    },
    accountType(id) {
      return this.types.find((element) => element.id == id).name;
    },
    remove(id) {
      showAlertWithConfirm().then((response) => {
        if (response.isConfirmed) {
          this.$store
            .dispatch("reviewer/requests/accounts/delete", id)
            .then(() => {
              successAlert();
              this.$store.commit("reviewer/requests/accounts/DELETE_USER", id);
            })
            .catch((error) =>
              errorAlert(
                error.response ? error.response.data.message : "فشل الحذف"
              )
            );
        }
      });
    },
    ImageURL(item) {
      return item.image ? item.image : ImageURL("user.jpg");
    },
  },
};
</script>

<style lang="scss">
td[aria-colindex="2"] {
  text-align: right;
}
td[aria-colindex="3"] {
  direction: ltr;
}
</style>
